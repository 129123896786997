<template>
  <div class="page-content">
    <div class="card">
      <div class="card-header">
        <b>{{ channel_obj.name }} Profile</b>
      </div>
      <div class="card-body row">
        <div class="col-8">
          <template v-if="channel_type === 'whatsapp'">
            <b-form @submit.prevent="updateProfile">
              <b-form-group
                :label="$t('channel_profile.about')">
                <el-input
                  show-word-limit
                  :maxlength="v$.form_profile.about.maxLength['$params'].max"
                  v-model="v$.form_profile.about.$model"
                  :placeholder="$t('general.empty_field')"/>
                <b-form-invalid-feedback :state="!v$.form_profile.about.$invalid" v-if="v$.form_profile.about.$error">
                  {{ v$.form_profile.about.$errors[0].$message }}
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                :label="$t('channel_profile.address')">
                <el-input
                  show-word-limit
                  :maxlength="v$.form_profile.address.maxLength['$params'].max"
                  type="textarea"
                  rows="3"
                  v-model="v$.form_profile.address.$model"
                  :placeholder="$t('general.empty_field')"/>
                <b-form-invalid-feedback :state="!v$.form_profile.address.$invalid" v-if="v$.form_profile.address.$error">
                  {{ v$.form_profile.address.$errors[0].$message }}
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                :label="$t('channel_profile.description')">
                <el-input
                  show-word-limit
                  :maxlength="v$.form_profile.description.maxLength['$params'].max"
                  type="textarea"
                  rows="6"
                  v-model="v$.form_profile.description.$model"
                  :placeholder="$t('general.empty_field')"/>
                <b-form-invalid-feedback :state="!v$.form_profile.description.$invalid" v-if="v$.form_profile.description.$error">
                  {{ v$.form_profile.about.$errors[0].$message }}
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                :label="$t('channel_profile.email')">
                <el-input
                  id="email"
                  v-model="v$.form_profile.email.$model"
                  show-word-limit
                  :maxlength="v$.form_profile.email.maxLength['$params'].max"
                  :placeholder="$t('general.empty_field')"/>
                <b-form-invalid-feedback :state="!v$.form_profile.email.$invalid" v-if="v$.form_profile.email.$error">
                  {{ v$.form_profile.email.$errors[0].$message }}
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                :label="$t('channel_profile.websites')">
                <div class="inline inline-flex align-middle" v-for="(val, index) in form_profile.websites" :key="index">
                  <el-input
                    v-model="val.url"
                    class="mb-2 w-50"
                    maxlength="128"
                    show-word-limit
                    :placeholder="$t('general.empty_field')"/>
                  <span>
                    <a @click="handlerRemoveWebsites(index)" class="cursor-pointer"><font-awesome class="text-danger" style="font-size: 1.5em" icon="times-circle"/></a>
                  </span>
                  <b-form-invalid-feedback :state="!v$.form_profile.websites.$invalid" v-if="v$.form_profile.websites.$error" class="w-50">
                    {{ v$.form_profile.websites.$errors[0].$message[index][0] }}
                  </b-form-invalid-feedback>
                </div>
                <div>
                  <el-button @click="handlerBtnAddMoreWebsite" size="mini" class="btn btn-info text-white"><font-awesome icon="plus"/>&nbsp; {{ $t('channel_profile.add_more_website') }}</el-button>
                </div>
              </b-form-group>
              <div class="d-flex float-right">
                <el-button @click="updateProfile" :loading="loading.update_profile" class="btn mr-2 btn-primary">{{ $t('general.submit') }}</el-button>
                <b-button type="reset" variant="secondary">{{ $t('general.cancel') }}</b-button>
              </div>
            </b-form>
          </template>
        </div>
        <div class="col-4">
          <!-- <img class="profile-pic" :src="profile.profile_picture_url" alt="profile"/> -->
          <div>
            <b-avatar size="150" :src="form_profile.base64_profile ? form_profile.base64_profile : profile.profile_picture_url" alt="profile"/>
          </div>
            <!-- <b-form-file @input="handleInputNewProfilePhoto" v-model="selected_file_profile" class="mt-3" accept=".jpeg, .jpg, .png" plain></b-form-file> -->
          <div>
            <button type="button" @click="toggleShow" class="btn btn-outline-primary btn-sm mt-3">{{ $t('channel_profile.set_avatar') }}</button>
          </div>
          <image-crop
            field="img"
            v-model="showCropImage"
            @crop-success="cropSuccess"
            :width="300"
            :height="300"
            img-format="png,jpg"
            lang-type="en"
            :no-square="true"
            :no-rotate="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { cloneDeep } from 'lodash';
import useVuelidate from '@vuelidate/core';
import {
  email, maxLength, url, helpers,
} from '@vuelidate/validators';
// import popupErrorMessages from '../../../library/popup-error-messages';
// import 'babel-polyfill';
import ImageCrop from 'vue-image-crop-upload/upload-2.vue';
import channelsAPI from '../../../api/channels';

export default {
  name: 'ChannelProfile',
  metaInfo: {
    title: 'Channel Profile',
  },
  components: {
    ImageCrop,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      form_profile: {
        email: { email, maxLength: maxLength(128) },
        about: { maxLength: maxLength(256) },
        address: { maxLength: maxLength(256) },
        description: { maxLength: maxLength(512) },
        websites: {
          maxLength: maxLength(2),
          $each: helpers.forEach({
            url: {
              url,
              maxLength: maxLength(256),
            },
          }),
        },
      },
    };
  },
  data() {
    return {
      profile: {},
      channel_type: '',
      channel_obj: {},
      channel_list: [
        { name: 'WhatsApp', icon: 'mdi mdi-whatsapp', value: 'whatsapp' },
        { name: 'Telegram', icon: 'mdi mdi-telegram', value: 'telegram' },
        { name: 'Email', icon: 'mdi mdi-email', value: 'email' },
        { name: 'Instagram', icon: 'mdi mdi-instagram', value: 'instagram' },
      ],
      loading: {
        update_profile: false,
      },
      form_profile: {
        about: '',
        address: '',
        description: '',
        email: '',
        websites: [],
        base64_profile: '',
      },
      selected_file_profile: null,
      showCropImage: false,
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
  },
  mounted() {
    this.getChannelProfile();
  },
  methods: {
    toggleShow() {
      this.showCropImage = !this.showCropImage;
    },
    // cropSuccess(imgDataUrl, field){
    cropSuccess(imgDataUrl) {
      console.log('-------- crop success --------');
      // this.imgDataUrl = imgDataUrl;
      this.form_profile.base64_profile = imgDataUrl;
    },
    async getChannelProfile() {
      this.$parent.showLoader();
      await channelsAPI.getChannelProfile({
        workspace_id: this.activeWorkspace._id,
        channel_id: this.$route.params.channel_id,
      }).then(({ data }) => {
        const { channel_type, profile } = data;
        this.channel_type = channel_type;
        this.channel_obj = this.channel_list.find((value) => value.value === channel_type);
        Object.keys(this.form_profile).forEach((key) => {
          if (key === 'websites') {
            this.$set(this.form_profile, key, profile[key].map((v) => ({ url: v })));
          } else {
            this.$set(this.form_profile, key, profile[key]);
          }
        });
        this.profile = profile;
        if (profile.photo) {
          this.profile.profile_picture_url = profile.photo;
        }
      }).catch((err) => console.log(err));
      this.$parent.hideLoader();
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => {
          console.log('Error: ', error);
          reject(error);
        };
      });
    },
    async updateProfile() {
      this.v$.form_profile.$touch();
      console.log(this.v$.form_profile.$errors);
      if (this.v$.form_profile.$error) return;
      this.$parent.showLoader();
      const form_obj = cloneDeep(this.form_profile);
      form_obj.websites = form_obj.websites.map((v) => v.url);
      await channelsAPI.updateChannelProfile({
        workspace_id: this.activeWorkspace._id,
        channel_id: this.$route.params.channel_id,
        data: form_obj,
      }).then(() => {
        this.$message({
          type: 'success',
          message: 'Profile updated.',
        });
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: 'Failed to update profile.',
        });
      });
      this.$parent.hideLoader();
    },
    async handleInputNewProfilePhoto(value) {
      this.form_profile.base64_profile = await this.getBase64(value);
    },
    handlerBtnAddMoreWebsite() {
      if (this.form_profile.websites.length === 2) {
        this.$message({
          type: 'warning',
          message: 'Maximum 2 websites',
        });
        return;
      }
      this.form_profile.websites.push({ url: '' });
    },
    handlerRemoveWebsites(index) {
      this.form_profile.websites.splice(index, 1);
    },
  },
};
</script>
